import Resizer from 'react-image-file-resizer'

export const limitFileSize = (file) => new Promise(resolve => {
  if (!file) resolve(null)
  const format = file.type === 'image/png' ? 'PNG' : 'JPEG'
  Resizer.imageFileResizer(file, 2048, 2048, format, 100, 0,
    uri => {
      resolve(uri)
    },
    'blob'
  )
})
