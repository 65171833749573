/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 2
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli-v2
 *
 * [View Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest)
 *
 * [Full Setup Instructions](https://data.eu.amplitude.com/bash/BASH/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [configuration]
 * @property {BrowserClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'production'|'development'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} production
 * @property {string} development
 */
export const ApiKey = {
  production: '2112f6c80c3f71ac71afe5a8dbd42452',
  development: 'f843f84e2798a799434518d137fc91dc'
};

/**
 * @typedef {Object} EventInformation
 * @param {number} event_id Property has no description in tracking plan.
 * @param {string} event_name Property has no description in tracking plan.
 * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} event_privacy_type Property has no description in tracking plan.
 * @param {'ACTIVE'|'CANCELLED'} event_state Property has no description in tracking plan.
 * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} guest_status Property has no description in tracking plan.
 */

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration = {
  plan: {
    version: '2',
    branch: 'main',
    source: 'web',
    versionId: '69dce308-0dda-4dfe-9de3-da8958925d19'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-javascript-ampli',
      sourceVersion: '2.0.0'
    }
  },
  serverZone: amplitude.Types.ServerZone.EU
};

export class Identify {
  constructor(properties) {
    this.event_type = amplitude.Types.SpecialEventType.IDENTIFY;
    this.event_properties = properties;
  }
}

export class AddedAvatar {
  constructor(properties) {
    this.event_type = 'added avatar';
    this.event_properties = properties;
  }
}

export class AddedBirthday {
  constructor(properties) {
    this.event_type = 'added birthday';
    this.event_properties = properties;
  }
}

export class AddedFriend {
  constructor(properties) {
    this.event_type = 'added friend';
    this.event_properties = properties;
  }
}

export class AddedName {
  constructor(properties) {
    this.event_type = 'added name';
    this.event_properties = properties;
  }
}

export class ClickedTicketlink {
  constructor(properties) {
    this.event_type = 'clicked ticketlink';
    this.event_properties = properties;
  }
}

export class CompletedSignIn {
  constructor() {
    this.event_type = 'completed sign in';
  }
}

export class CreatedEvent {
  constructor(properties) {
    this.event_type = 'created event';
    this.event_properties = properties;
  }
}

export class CreatedOrganisation {
  constructor() {
    this.event_type = 'created organisation';
  }
}

export class Downloaded {
  constructor(properties) {
    this.event_type = 'downloaded';
    this.event_properties = properties;
  }
}

export class FollowedOrganisation {
  constructor(properties) {
    this.event_type = 'followed organisation';
    this.event_properties = properties;
  }
}

export class InvitedUserToEvent {
  constructor(properties) {
    this.event_type = 'invited user to event';
    this.event_properties = properties;
  }
}

export class LikedChatMessage {
  constructor(properties) {
    this.event_type = 'liked chat message';
    this.event_properties = properties;
  }
}

export class OpenedEventPage {
  constructor(properties) {
    this.event_type = 'opened event page';
    this.event_properties = properties;
  }
}

export class OpenedGuestlist {
  constructor(properties) {
    this.event_type = 'opened guestlist';
    this.event_properties = properties;
  }
}

export class OpenedOrganisationPage {
  constructor(properties) {
    this.event_type = 'opened organisation page';
    this.event_properties = properties;
  }
}

export class OpenedUserGeneratedLink {
  constructor(properties) {
    this.event_type = 'opened user generated link';
    this.event_properties = properties;
  }
}

export class OpenedUserPage {
  constructor(properties) {
    this.event_type = 'opened user page';
    this.event_properties = properties;
  }
}

export class RespondedToEvent {
  constructor(properties) {
    this.event_type = 'responded to event';
    this.event_properties = properties;
  }
}

export class SelectedSignInMethod {
  constructor(properties) {
    this.event_type = 'selected sign in method';
    this.event_properties = properties;
  }
}

export class SendChatMessage {
  constructor(properties) {
    this.event_type = 'send chat message';
    this.event_properties = properties;
  }
}

export class SharedEvent {
  constructor(properties) {
    this.event_type = 'shared event';
    this.event_properties = properties;
  }
}

export class StartedEventCreation {
  constructor() {
    this.event_type = 'started event creation';
  }
}

export class StartedRespondingToEvent {
  constructor(properties) {
    this.event_type = 'started responding to event';
    this.event_properties = properties;
  }
}

export class VerifiedResponse {
  constructor(properties) {
    this.event_type = 'verified response';
    this.event_properties = properties;
  }
}

/**
 * @typedef PromiseResult
 * @type {object}
 * @property {Promise|undefined} promise
 */

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {BrowserClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
  }

  /**
   * @return {BrowserClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param {LoadOptions} options Configuration options to initialize the Ampli SDK with. 'environment', 'client.apiKey' or 'client.instance' is required.
   *
   * @return {PromiseResult}
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey;
    if (options?.client?.apiKey) {
      apiKey = options.client.apiKey;
    } else if (options?.environment) {
      apiKey = ApiKey[options.environment];
    }

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options?.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {string} [properties.Admin of number of organisations] Property has no description in tracking plan.
   * @param {string} [properties.Birthday] Property has no description in tracking plan.
   * @param {string} [properties.Following number of organisations] Property has no description in tracking plan.
   * @param {number} [properties.Friends] Property has no description in tracking plan.
   * @param {string} [properties.Number of events hosted] Property has no description in tracking plan.
   * @param {number} [properties.Number of events joined] Property has no description in tracking plan.
   * @param {number} [properties.Number of friends] Property has no description in tracking plan.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options, user_id: userId};
    }

    const ampIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        ampIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(ampIdentify, options);
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   *
   * @return {PromiseResult}
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude.track(event, undefined, options);
  }

  /**
   * added avatar
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/added%20avatar)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {'CHOOSE_IMAGE'|'MAKE_IMAGE'} properties.image_selection_method Property has no description in tracking plan.
   * @param {'ONBOARDING'|'OWN_PROFILE'|'FINISH_PROFILE_POPUP'|'FINISH_PROFILE_RSVP_STEP'} properties.profile_location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  addedAvatar(properties, options) {
    return this.track(new AddedAvatar(properties), options);
  }

  /**
   * added birthday
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/added%20birthday)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.birthday Needs to be in the format: **YYYY-MM-DDTHH:mm:ss**
   * @param {'ONBOARDING'|'OWN_PROFILE'|'FINISH_PROFILE_POPUP'|'FINISH_PROFILE_RSVP_STEP'} properties.profile_location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  addedBirthday(properties, options) {
    return this.track(new AddedBirthday(properties), options);
  }

  /**
   * added friend
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/added%20friend)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {'GUESTLIST'|'SEARCH'|'ONBOARDING_CONTACT_SYNC'|'ONBOARDING_FRIEND_SCREEN'|'FRIEND_SCREEN'|'USER_PROFILE'|'FEED'|'IN_FEED_SUGGESTIONS'|'FOLLOWER_LIST'|'FRIEND_LIST'|'HOSTED_BY_POPUP'|'SHARED_BY_POPUP'} properties.add_friend_location Property has no description in tracking plan.
   * @param {number} [properties.friend_suggestion_id] add the friend suggestion id if there is one
   * @param {EventOptions} [options] Options for this track call.
   */
  addedFriend(properties, options) {
    return this.track(new AddedFriend(properties), options);
  }

  /**
   * added name
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/added%20name)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.name Property has no description in tracking plan.
   * @param {'ONBOARDING'|'OWN_PROFILE'|'FINISH_PROFILE_POPUP'|'FINISH_PROFILE_RSVP_STEP'} properties.profile_location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  addedName(properties, options) {
    return this.track(new AddedName(properties), options);
  }

  /**
   * clicked ticketlink
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/clicked%20ticketlink)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  clickedTicketlink(properties, options) {
    return this.track(new ClickedTicketlink(properties), options);
  }

  /**
   * completed sign in
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/completed%20sign%20in)
   *
   * Owner: Elise Mol
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  completedSignIn(options) {
    return this.track(new CompletedSignIn(), options);
  }

  /**
   * created event
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/created%20event)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  createdEvent(properties, options) {
    return this.track(new CreatedEvent(properties), options);
  }

  /**
   * created organisation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/created%20organisation)
   *
   * Owner: Elise Mol
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  createdOrganisation(options) {
    return this.track(new CreatedOrganisation(), options);
  }

  /**
   * downloaded
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/downloaded)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {} properties.download_location Property has no description in tracking plan.
   * @param {boolean} properties.logged in Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  downloaded(properties, options) {
    return this.track(new Downloaded(properties), options);
  }

  /**
   * followed organisation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/followed%20organisation)
   *
   * Owner: Elise Mol
   *
   * @param {Object} [properties] The event's properties.
   * @param {'ORGANISATION_CARD_ON_EVENT'|'FEED'|'ORGANISATION_PAGE'} [properties.follow_location] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  followedOrganisation(properties, options) {
    return this.track(new FollowedOrganisation(properties), options);
  }

  /**
   * invited user to event
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/invited%20user%20to%20event)
   *
   * When you invite someone to an event
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  invitedUserToEvent(properties, options) {
    return this.track(new InvitedUserToEvent(properties), options);
  }

  /**
   * liked chat message
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/liked%20chat%20message)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.message id Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  likedChatMessage(properties, options) {
    return this.track(new LikedChatMessage(properties), options);
  }

  /**
   * opened event page
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/opened%20event%20page)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {boolean} properties.logged in Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  openedEventPage(properties, options) {
    return this.track(new OpenedEventPage(properties), options);
  }

  /**
   * opened guestlist
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/opened%20guestlist)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {boolean} properties.logged in Property has no description in tracking plan.
   * @param {'HYPE'|'GOING'|'CANT'|'MAYBE'|'NONE'} properties.status_filter Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  openedGuestlist(properties, options) {
    return this.track(new OpenedGuestlist(properties), options);
  }

  /**
   * opened organisation page
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/opened%20organisation%20page)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {boolean} properties.logged in Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  openedOrganisationPage(properties, options) {
    return this.track(new OpenedOrganisationPage(properties), options);
  }

  /**
   * opened user generated link
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/opened%20user%20generated%20link)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {'EVENT'|'USER'|'ORGANISATION'} properties.link type Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  openedUserGeneratedLink(properties, options) {
    return this.track(new OpenedUserGeneratedLink(properties), options);
  }

  /**
   * opened user page
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/opened%20user%20page)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  openedUserPage(properties, options) {
    return this.track(new OpenedUserPage(properties), options);
  }

  /**
   * responded to event
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/responded%20to%20event)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {boolean} properties.logged in Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  respondedToEvent(properties, options) {
    return this.track(new RespondedToEvent(properties), options);
  }

  /**
   * selected sign in method
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/selected%20sign%20in%20method)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {'EMAIL'|'APPLE'|'GOOGLE'} properties.sign_in_method Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  selectedSignInMethod(properties, options) {
    return this.track(new SelectedSignInMethod(properties), options);
  }

  /**
   * send chat message
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/send%20chat%20message)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {boolean} properties.contains_image Property has no description in tracking plan.
   * @param {number} properties.message id Property has no description in tracking plan.
   * @param {boolean} properties.response_to_message Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  sendChatMessage(properties, options) {
    return this.track(new SendChatMessage(properties), options);
  }

  /**
   * shared event
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/shared%20event)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {'WHATSAPP'|'GENERIC'|'CHAT'|'INSTAGRAM'|'POST'} properties.share_type Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  sharedEvent(properties, options) {
    return this.track(new SharedEvent(properties), options);
  }

  /**
   * started event creation
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/started%20event%20creation)
   *
   * Owner: Elise Mol
   *
   * @param {EventOptions} [options] Options for this track call.
   */
  startedEventCreation(options) {
    return this.track(new StartedEventCreation(), options);
  }

  /**
   * started responding to event
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/started%20responding%20to%20event)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {boolean} properties.logged in Property has no description in tracking plan.
   * @param {'LOCKED_GUESTLIST'|'LOCKED_CHAT'|'RSVP_BUTTONS'} properties.respond_location Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  startedRespondingToEvent(properties, options) {
    return this.track(new StartedRespondingToEvent(properties), options);
  }

  /**
   * verified response
   *
   * [View in Tracking Plan](https://data.eu.amplitude.com/bash/BASH/events/main/latest/verified%20response)
   *
   * Owner: Elise Mol
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.event_id Property has no description in tracking plan.
   * @param {string} properties.event_name Property has no description in tracking plan.
   * @param {'PRIVATE_EVENT'|'PUBLIC_EVENT'|'FRIENDS_EVENT'|'FOLLOWERS_EVENT'} properties.event_privacy_type Property has no description in tracking plan.
   * @param {'ACTIVE'|'CANCELLED'} properties.event_state Property has no description in tracking plan.
   * @param {'INVITED'|'NONE'|'GOING'|'MAYBE'|'CANT'|'INTERESTED'} properties.guest_status Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   */
  verifiedResponse(properties, options) {
    return this.track(new VerifiedResponse(properties), options);
  }
}

export const ampli = new Ampli();
